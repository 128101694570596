import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    offset: Number(localStorage.getItem('offset')) || 10
  },
  getters: {},
  mutations: {
    setOffset(state, offset) {
      state.offset = offset
      localStorage.setItem('offset', offset)
    }
  },
  actions: {},
  modules: {}
})